import {AxiosPromise} from "axios";
import {CONSTANT_CONFIG} from "@/constants";
import { HttpService } from "@/services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.CORE_SERVER_PREFIX}/import`;

const endpoints = {
    importExample: () => `${RESOURCE_ENDPOINT}/example`,
    importLocation: () => `${RESOURCE_ENDPOINT}/location`,
}

export default class ImportCoreApi {
    public importExample = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.importExample();
        return HttpService.post(url, payload, params, headers);
    }
    public importLocation = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.importLocation();
        return HttpService.post(url, payload, params, headers);
    }
}

